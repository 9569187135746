<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('elearning_tim.attendance') }}
              <slot v-if="$route.query.id">
                {{ $t('globalTrans.update') }}
              </slot>
              <slot v-else>
                {{ $t('globalTrans.entry') }}
              </slot>
            </h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="attendance" :class="'btn btn-success text-light'">{{ $t('elearning_tim.attendance') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                    <!-- <b-row>
                      <b-col lg="6" sm="6">
                        <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="circular_memo_no"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="formData.circular_memo_no"
                                :options="circularList"
                                id="circular_memo_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row> -->
                    <b-overlay :show="load">
                      <b-row>
                      </b-row>
                      <b-row>
                        <div class="col-md-12">
                          <fieldset class="p-2 w-100">
                            <legend class="px-2 w-50 shadow-sm">{{ $t('elearning_tim.attendance') }} {{ $t('globalTrans.list') }}</legend>
                              <!-- <div style="border: 2px solid #EBEBEF ;margin:10px;padding:10px">
                                  <b-row>
                                      <b-col v-for="(info, index) in dateList" :key="index">
                                        <b-form-checkbox
                                          :id="'checkbox-' + info.text"
                                          v-model="info.checked"
                                          :name="'checkbox-' + info.text"
                                          value=2
                                          unchecked-value=1
                                          @change="checkCount(info)"
                                        >
                                        {{info.text | dateFormat}}
                                        </b-form-checkbox>
                                      </b-col>
                                  </b-row>
                              </div> -->
                              <b-overlay :show="loading">
                                <b-table-simple hover bordered responsive >
                                  <b-thead class="thead">
                                    <b-tr>
                                      <b-th rowspan="3" class="text-center" style="width:2%;vertical-align:middle">{{$t('globalTrans.sl_no')}}</b-th>
                                      <!-- <b-th rowspan="3" style="vertical-align:middle">{{$t('elearning_tim.trainee')}}</b-th>
                                      <b-th rowspan="3" class="text-center" style="vertical-align:middle">{{$t('globalTrans.designation')}}</b-th>
                                      <b-th rowspan="3" class="text-center" style="vertical-align:middle">{{$t('elearning_tim.posting_office')}}</b-th> -->
                                      <b-th style="width:10%;vertical-align:middle" class="text-center" :colspan="dateList.length * 2">{{ $t('globalTrans.date') }}</b-th>
                                    </b-tr>
                                  <b-tr>
                                    <slot v-for="(field) in dateList">
                                      <b-th class="text-center" colspan="2">
                                          {{ field.text | dateFormat }}
                                      </b-th>
                                    </slot>
                                  </b-tr>
                                  <b-tr>
                                    <slot v-for="field in dateList">
                                      <b-th class="text-center" :id="'morning-' + field.value">
                                        {{$t('globalTrans.morning')}}
                                      </b-th>
                                      <b-th class="text-center" :id="'day-' + field.value">
                                        {{$t('globalTrans.evening')}}
                                      </b-th>
                                    </slot>
                                  </b-tr>
                                  </b-thead>
                                    <b-tr v-for="(train,index) in details" :key="index">
                                      <b-td class="text-center">{{$n(index+1)}}</b-td>
                                      <!-- <b-td>
                                        {{ ($i18n.locale==='bn') ? train.name_bn : train.name }}
                                      </b-td>
                                      <b-td class="text-center">
                                        {{ ($i18n.locale==='bn') ? train.designation_name_bn : train.designation_name }}
                                      </b-td>
                                      <b-td class="text-center">
                                        {{ (($i18n.locale==='bn') ? train.office_name_bn : train.office_name) + ', ' + (($i18n.locale==='bn') ? train.org_name_bn : train.org_name) }}
                                      </b-td> -->
                                      <slot v-for="(field, index1) in dateList">
                                        <b-th class="text-center">
                                          <input :disabled="details[index].dateList[index1].attendence_status || checkDate(field)" type="checkbox" v-model="details[index].dateList[index1].morning">
                                        </b-th>
                                        <b-th class="text-center">
                                          <input :disabled="details[index].dateList[index1].attendence_status || checkDate(field)" type="checkbox" v-model="details[index].dateList[index1].day">
                                        </b-th>
                                      </slot>
                                    </b-tr>
                                    <template v-if="details.length === 0">
                                      <tr>
                                        <th :colspan="dateList.length * 2 + 6" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                                      </tr>
                                    </template>
                                </b-table-simple>
                              </b-overlay>
                          </fieldset>
                        </div>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <router-link to="attendance" class="mr-1 btn btn-danger">{{ $t('globalTrans.cancel') }}</router-link>
                        </div>
                      </div>
                    </b-overlay>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { attendanceStore, circularPublication, trainingApplicationIndividualList, userCircularList } from '../../api/routes'
import flatpickr from 'flatpickr'
const today = new Date().toISOString().substr(0, 10)

export default {
  props: ['id'],
  components: {
    ValidationObserver
  },
  created () {
    this.getAttedenceList()
    this.getCircularMemoNo()
    // this.getCircularMemoNo()
    this.search = Object.assign({}, this.search, {
        fiscal_year_id: this.getCurrentFiscalYearId(this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0))
    })
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      load: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        circular_memo_no: this.$route.query.circularMemoNo,
        fiscal_year_id: 0,
        org_id: 0,
        training_category_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        batch_no: 0,
        training_date: '',
        details: []
      },
      trainingCalendar: {
        training_start_date: '',
        training_end_date: ''
      },
      details: [],
      detailsErrors: [],
      trainerLoading: false,
      circularList: [],
      circularLoading: false,
      trainingCategoryList: [],
      attachments_errors: [],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainerListData: [],
      trainingTitleList: [],
      allBatchListData: [],
      batchList: [],
      detailErrors: [],
      approvedDate: '',
      dateList: []
    }
  },
  computed: {
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' }
      ]
    },
    orgList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0).map(obj => {
              if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
              } else {
              return { value: obj.value, text: obj.text_en }
              }
          })
    },
    trainingTypeList: function () {
      return this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  watch: {
    // 'formData.circular_memo_no': function (newVal, oldVal) {
    //   this.getCircularMemoNo()
    //   // this.getAttedenceList()
    //   if (newVal !== oldVal) {
    //     const batchData = this.allBatchListData.filter(allBatchList => String(allBatchList.circular_memo_no) === String(newVal))
    //     const batchList = []
    //     batchData.map((obj, index) => {
    //       if (obj.batch_no) {
    //         const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
    //         if (typeof isThere !== 'undefined') {
    //           batchList.push(isThere)
    //         }
    //       }
    //     })
    //     this.batchList = batchList
    //     if (this.batchList.length === 1) {
    //       this.search.batch_no = 1
    //     }
    //   }
    // },
    // 'formData.training_type_id': function (newValue) {
    //   this.trainingCategoryList = this.getCategoryList(newValue)
    // },
    // 'formData.training_category_id': function (newValue) {
    //   this.trainingTitleList = this.getTrainingTitleList(newValue)
    // },
    // 'formData.training_title_id': function (newValue) {
    //   this.getCircularList()
    // },
    // 'formData.batch_no': function (newValue) {
    //   this.getAttedenceList()
    // }
  },
  methods: {
    getCurrentFiscalYearId (fiscalYearList) {
        if (fiscalYearList.length === 0) {
          return
        }
        const currentDate = new Date()
        const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
        /** Define the year position  */
        const yearPosition = month < 7 ? 5 : 0
        const yearStr = `${year}`
        let currentFiscalYearId = 0
        fiscalYearList.forEach(element => {
          if (element.text_en.indexOf(yearStr) === yearPosition) {
            currentFiscalYearId = element.value
          }
        })
        return currentFiscalYearId
    },
    checkCount (item) {
      const total = this.dateList.filter(item1 => item1.checked === '2')
      if (total.length > 10) {
        this.$toast.error({
          title: 'Error',
          message: this.$i18n.locale === 'bn' ? 'ইতিমধ্যে সব তারিখ যোগ করা হয়েছে!' : 'All Dates Already Added!'
        })
        item.checked = false
        item.checAll = false
      }
    },
    async getCircularList () {
      this.circularLoading = true
      const serchData = {
        fiscal_year_id: this.formData.fiscal_year_id,
        training_type_id: this.formData.training_type_id,
        training_category_id: this.formData.training_category_id,
        training_title_id: this.formData.training_title_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, userCircularList, serchData)
      if (!result.success) {
        this.circularList = []
        this.allBatchListData = []
      } else {
        this.allBatchListData = result.batchList
        const listObject = result.data
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          }
        })
        this.circularList = tmpList
      }
      this.circularLoading = false
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.ExternalUserIrrigation.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.training_type_id === typeId)
       }
       return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    onChangeFile (e) {
      this.formData.upload_guidelines = e.target.files[0]
    },
    async getAttedenceList () {
      if (this.$route.query.circularMemoNo) {
        this.loading = true
        const params = {
          circular_memo_no: this.$route.query.circularMemoNo,
          batch_no: this.$route.query.batchNo
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingApplicationIndividualList, params)
        if (result.success) {
          this.circularWiseAttendanceList = result.circularWiseAttendance
          const listData = result.aryRange.map(item => {
            const updateData = {}
            updateData.checked = false
            updateData.morning = false
            updateData.day = false
            updateData.text = item
            return Object.assign({}, updateData)
          })
          this.dateList = listData
          const datas = result.data.map(item => {
            var dataEvent = []
            const desigObj = this.$store.state.ExternalUserIrrigation.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
            const desigData = {}
            if (typeof desigObj !== 'undefined') {
              desigData.designation_name = desigObj.text_en
              desigData.designation_name_bn = desigObj.text_bn
            } else {
              desigData.designation_name = ''
              desigData.designation_name_bn = ''
            }
            const orgObj = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(org => org.value === parseInt(item.professional_org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
              orgData.org_name = orgObj.text_en
              orgData.org_name_bn = orgObj.text_bn
            } else {
              orgData.org_name = ''
              orgData.org_name_bn = ''
            }
            const officeObj = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(office => office.value === parseInt(item.office_id))
            const officeData = {}
            if (typeof officeObj !== 'undefined') {
              officeData.office_name = officeObj.text_en
              officeData.office_name_bn = officeObj.text_bn
            } else {
              officeData.office_name = ''
              officeData.office_name_bn = ''
            }
            this.dateList.forEach((dateList) => {
              const saveObj = this.circularWiseAttendanceList.find(circularWiseAttendance => circularWiseAttendance.training_application_id === item.id && circularWiseAttendance.training_date === dateList.text)
              const items = {}
              items.training_application_id = item.id
              items.attendence_status = false
              if (typeof saveObj !== 'undefined') {
                if (saveObj.status === 1) {
                  items.attendence_status = true
                }
                items.morning = saveObj.morning
                items.day = saveObj.day
                items.text = dateList.text
              } else {
                items.morning = false
                items.day = false
                items.text = dateList.text
              }
              dataEvent.push(items)
            })
            const newData = {
                dateList: dataEvent
            }
            return Object.assign({}, item, desigData, newData, orgData, officeData)
          })
          this.details = datas
          this.loading = false
        } else {
          this.details = {}
          this.loading = false
        }
      }
    },
    checkData (item, type) {
      this.details.forEach((detail, detailIndex) => {
        detail.dateList.forEach((dateList, dateListIndex) => {
          if (dateList.text === item.text) {
            if (type === 'morning') {
              if (item.morning === '2') {
                dateList.morning = true
              } else {
               dateList.morning = false
              }
            } else {
              if (item.day === '2') {
                dateList.day = true
              } else {
               dateList.day = false
              }
            }
          }
        })
      })
    },
    async getCircularMemoNo () {
      this.load = true
      const params = {
        circular_memo_no: this.$route.query.circularMemoNo,
        table: 'iab_circular_publications'
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication, params)
      if (!result.success) {
        this.formData.org_id = 0
      } else {
        this.lcErrorMsg = ''
        const trData = result.data
        this.trainingCalendar = result.training_calendar
        this.formData.org_id = trData.org_id
        this.formData.fiscal_year_id = trData.fiscal_year_id
        this.formData.training_type_id = trData.training_type_id
        this.formData.training_category_id = trData.training_category_id
        this.formData.training_title_id = trData.training_title_id
        this.formData.batch_no = trData.batch_no
        this.load = false
      }
      this.load = false
    },
    async createData () {
      this.loading = true
      let result = null
      this.formData.details = this.details
      if (this.formData.id) {
        // formData.append('_method', 'POST')
        // result = await RestApi.postData(trainingElearningServiceBaseUrl, `${trainingScheduleUpdate}/${this.formData.id}`, this.formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, attendanceStore, this.formData)
      }
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        // this.getAttedenceList()
        this.$router.push('/trainee-trainer-panel/attendance')
      } else {
        this.detailErrors = result.errors
        this.$refs.form.setErrors(result.errors)
      }
    },
    checkDate (item) {
      if (item.text > today) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
